import {computed, makeObservable} from 'mobx';
import {ca2servers, ca2types} from '../../api/proto';
import PriceFormatter from '../../utils/priceFormatter';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Addon from './Addon';
import ServerOptions from './options/ServerOptions';
import StringableServerOptions from './options/StringableServerOptions';

export class ServersStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);
  }

  cpuModels: StringableServerOptions = new StringableServerOptions();
  gpuModels: StringableServerOptions = new StringableServerOptions();
  tabs: ServerOptions<ca2types.ITab> = new ServerOptions();
  providers: ServerOptions<ca2types.IProvider> = new ServerOptions();
  benefits: ServerOptions<ca2types.IBenefit> = new ServerOptions();
  addons: ServerOptions<Addon> = new ServerOptions();
  regions: ServerOptions<ca2types.IRegion> = new ServerOptions();
  datacenters: ServerOptions<ca2types.IDatacenter> = new ServerOptions();

  init = async () => {
    await this.loadOptions_();

    await this.app.plansStore.reload();
  };

  reset = () => {
    this.providers.reset();
    this.tabs.reset();
    this.cpuModels.reset();
    this.gpuModels.reset();
    this.regions.reset();
    this.benefits.reset();
    this.datacenters.reset();
    this.addons.reset();
  };

  resetSelectedOptions = () => {
    this.providers.setSelectedId(null);
    this.cpuModels.setSelectedId(null);
    this.gpuModels.setSelectedId(null);
    this.regions.setSelectedId(null);
  };

  private loadOptions_ = async () => {
    const {res} = await this.request({
      servers: {
        regionsList: {},
        tabsList: {},
        cpuModelsList: {},
        gpuModelsList: {},
        providersList: {},
        benefits: {},
        addonsList: {},
        datacenterList: {},
        priceRange: {},
      },
    });

    if (res?.servers) {
      this.processLoadOptions_(res.servers);
    }
  };

  private processLoadOptions_ = ({
    regionsList,
    tabsList,
    cpuModelsList,
    gpuModelsList,
    providersList,
    benefits,
    addonsList,
    datacenterList,
    priceRange,
  }: ca2servers.IResponse) => {
    if (regionsList?.items) {
      this.regions.init(regionsList.items);
    }

    if (tabsList?.items) {
      this.tabs.init(tabsList.items);
      this.tabs.setSelectedId(tabsList.items?.[0].id || null);
    }

    if (cpuModelsList?.models) {
      this.cpuModels.init(cpuModelsList.models);
    }

    if (gpuModelsList?.models) {
      this.gpuModels.init(gpuModelsList.models);
    }

    if (providersList?.items) {
      this.providers.init(providersList.items);
    }

    if (benefits?.items) {
      this.benefits.init(benefits.items);
    }

    if (addonsList?.items) {
      this.addons.init(addonsList.items.map((raw) => new Addon(raw)));
    }

    if (datacenterList?.items) {
      this.datacenters.init(datacenterList.items);
    }

    if (priceRange?.maxMonthlyRate) {
      const priceRangeFormatter = new PriceFormatter(priceRange?.maxMonthlyRate);

      this.app.plansStore.filter.setMaxMonthlyRateUsd(priceRangeFormatter.dollars);
    }
  };

  loadPaymentOptions = async (paymentOptions: ca2servers.IPaymentOptionsRequest) => {
    const {res} = await this.request({
      servers: {
        paymentOptions,
      },
    });

    return res?.servers?.paymentOptions || null;
  };

  changeProvider = async (providerId: string | null) => {
    this.providers.setSelectedId(providerId || null);

    this.app.plansStore.reload();
  };

  @computed get isGpuTabActive() {
    return this.tabs.selectedId === 'gpu';
  }

  changeServerTab = (tabId: string) => {
    this.tabs.setSelectedId(tabId);

    this.app.plansStore.reload();
  };

  changeCpuModel = (modelId: string | null) => {
    this.cpuModels.setSelectedId(modelId);

    this.app.plansStore.reload();
  };

  changeGpuModel = (modelId: string | null) => {
    this.gpuModels.setSelectedId(modelId);

    this.app.plansStore.reload();
  };

  changeRegion = (regionId: string | null) => {
    this.regions.setSelectedId(regionId);

    this.app.plansStore.reload();
  };
}

export default ServersStore;

import {Tooltip, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';

export const TruncatedTooltip: React.FC<React.PropsWithChildren> = ({children}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [children]);

  return (
    <Tooltip title={isTruncated ? children : ''} disableHoverListener={!isTruncated}>
      <Typography
        ref={textRef}
        component="div"
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTooltip;

import {Box, Button, FormControlLabel, TableCell, Tooltip, Typography, useTheme} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as WarningIcon} from '../../../assets/icons/warning-orange.svg';
import {Switch} from '../../../components/UI/Switch';
import {navigateToDomainInfo} from '../../../routes/navigateTo';
import {useStore} from '../../../stores/AppStore';
import {Domain} from '../../../stores/Domain';
import {ModalType} from '../../../stores/ModalsStore';
import {formatTimestamp} from '../../../utils/format/format';
import {DomainInfoTabs} from '../../DomainInfoPage/DomainInfoViewer';
import {DOMAINS_TABLE_COLUMNS, DomainsColumnType} from './DomainsTable';

interface IProps {
  domain: Domain;
}

export const DomainTableRow: React.FC<IProps> = observer(({domain}) => {
  const {modals} = useStore();
  const {t} = useTranslation();
  const theme = useTheme();
  const [isTooltipHovered, setIsTooltipHovered] = React.useState(false);

  const handleToggleRenewal = () => {
    if (domain.autoRenew) {
      modals.open(ModalType.CONFIRM, {
        maxWidth: 'sm',
        headerText: t('modal_confirm_disable_domain_auto_renewal_header_text'),
        title: <Typography variant="body1">{t('modal_confirm_disable_domain_auto_renewal_title')}</Typography>,
        submitButtonText: t('modal_confirm_disable_domain_auto_renewal_submit'),
        submitButtonColor: 'primary',
        content: <Typography variant="body1">{t('modal_confirm_disable_domain_auto_renewal_text')}</Typography>,
        callbacks: {
          submit: domain.toggleAutoRenew,
        },
      });
    } else {
      domain.toggleAutoRenew();
    }
  };

  const handleClickRenewBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigateToDomainInfo(domain, DomainInfoTabs.RenewPayment);
  };

  const isExpiresOnThreeDays = domain.daysUntilExpirationLessThanMonth && domain.daysUntilExpirationLessThanMonth <= 3;

  const getTableCellComponent = (columnId: DomainsColumnType) => {
    switch (columnId) {
      case DomainsColumnType.DomainName:
        return (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <span>{domain.name}</span>
            {domain.needConfirmation && (
              <Tooltip
                title={t('domains_table_need_domain_confirmation_message')}
                onOpen={() => setIsTooltipHovered(true)}
                onClose={() => setIsTooltipHovered(false)}
                disableHoverListener={false}
              >
                <Box
                  sx={{
                    '& svg circle': {
                      fill: isTooltipHovered ? `${theme.palette.warning.tertiary} !important` : 'currentcolor',
                    },
                  }}
                >
                  <WarningIcon />
                </Box>
              </Tooltip>
            )}
          </Box>
        );
      case DomainsColumnType.RegistrationDate:
        return formatTimestamp(domain.createdAtTimestamp, false);
      case DomainsColumnType.ExpirationDate:
        return (
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            {formatTimestamp(domain.expiresAtTimestamp, false)}

            {domain.daysUntilExpirationLessThanMonth ? (
              <Box sx={{display: 'flex', border: `1px solid ${theme.palette.border.primary}`, borderRadius: '9px'}}>
                <Typography
                  variant="body2"
                  sx={{
                    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                    backgroundColor: isExpiresOnThreeDays
                      ? theme.palette.warning.alpha1
                      : theme.palette.backgroundSecondary,
                    borderRadius: '6px',
                    margin: '2px',
                    marginRight: theme.spacing(0),
                    color: isExpiresOnThreeDays ? theme.palette.warning.primary : theme.palette.body.primary,
                  }}
                >
                  {t(
                    domain.daysUntilExpirationLessThanMonth === 1
                      ? 'domains_table_day_left'
                      : 'domains_table_days_left',
                    {days: domain.daysUntilExpirationLessThanMonth},
                  )}
                </Typography>
                <Button size="small" variant="outlined" onClick={handleClickRenewBtn}>
                  {t('domains_table_renew_button')}
                </Button>
              </Box>
            ) : null}
          </Box>
        );
      case DomainsColumnType.AutoRenewal:
        return (
          <Box sx={{marginLeft: theme.spacing(2)}}>
            <FormControlLabel
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={handleToggleRenewal}
              control={<Switch />}
              labelPlacement="end"
              checked={!!domain.autoRenew}
              label={domain.autoRenew ? t('domains_table_enabled_label') : t('domains_table_disabled_label')}
              sx={{'& .MuiTypography-root': {marginLeft: theme.spacing(2)}}}
            />
          </Box>
        );
    }
  };

  return (
    <React.Fragment>
      {DOMAINS_TABLE_COLUMNS.map((column) => (
        <TableCell
          align="left"
          key={column.id}
          sx={{
            maxWidth: column.width,
            width: column.width,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {getTableCellComponent(column.id)}
        </TableCell>
      ))}
    </React.Fragment>
  );
});

export default DomainTableRow;

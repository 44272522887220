import {observer} from 'mobx-react';
import {Suspense} from 'react';
import {Routes as BrowserRoutes, Navigate, Route, useLocation, useNavigate} from 'react-router-dom';

import React from 'react';
import {useStore} from '../stores/AppStore';
import {DOMAIN_NAME_TO_SEARCH_STORE_KEY} from '../stores/Domain/DomainsStore';
import {SERVER_PLAN_ID_STORE_KEY} from '../stores/Plan/PlansStore';
import {browserStorage} from '../utils/browserStorage';
import {BrowserRouter} from './BrowserRouter';
import Paths from './Paths';
import browserHistory from './browserHistory';
import {navigateToCreateServer, navigateToDomains} from './navigateTo';
import {IRouteProps, routes} from './routes';

const AppRouteLayout = observer(({component: Component, layout: Layout, isAuthProtected, ...rest}: IRouteProps) => {
  const location = useLocation();
  const {authStore} = useStore();
  const navigate = useNavigate();

  const domainNameToSearch = browserStorage.getItem(DOMAIN_NAME_TO_SEARCH_STORE_KEY);
  const serverPlanId = browserStorage.getItem(SERVER_PLAN_ID_STORE_KEY);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const domainName = decodeURIComponent(params.get('searchDomain') || '');
    const planId = decodeURIComponent(params.get('serverPlanId') || '');

    if (domainName) {
      browserStorage.setItem(DOMAIN_NAME_TO_SEARCH_STORE_KEY, domainName);
      navigate(location.pathname, {replace: true});
    }

    if (planId) {
      browserStorage.setItem(SERVER_PLAN_ID_STORE_KEY, planId);
      navigate(location.pathname, {replace: true});
    }
  }, [location, navigate]);

  React.useEffect(() => {
    if (domainNameToSearch && authStore.isLoggedIn && location.pathname !== Paths.Domains) {
      navigateToDomains();
    }

    if (serverPlanId && authStore.isLoggedIn && location.pathname !== Paths.CreateServer) {
      navigateToCreateServer();
    }
  }, [domainNameToSearch, serverPlanId, authStore.isLoggedIn, location.pathname]);

  if (isAuthProtected && !authStore.isLoggedIn && location.pathname !== Paths.Authorization) {
    return <Navigate to={Paths.Authorization} replace state={{from: location}} />;
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
});

export const Routes = observer(() => {
  return (
    <BrowserRouter history={browserHistory}>
      <Suspense fallback={<div />}>
        <BrowserRoutes>
          {routes.map((route, idx) => (
            <Route key={idx} element={<AppRouteLayout {...route} />} path={route.path} />
          ))}
        </BrowserRoutes>
      </Suspense>
    </BrowserRouter>
  );
});

export default Routes;

import {Box} from '@mui/material';
import {observer} from 'mobx-react';
import React from 'react';
import {ca2instances} from '../../../api/proto';
import Loader from '../../../components/UI/Loader';
import {Plan} from '../../../stores/Plan';
import SetupPlanForm from './SetupPlanForm';

interface IProps {
  plan: Plan;
  initialValues?: ca2instances.ICreateInstanceRequest | null;
  onSwitchToChoosePlan?(): void;
  onSubmit(setupPlanFormData: ca2instances.ICreateInstanceRequest): void;
}

const SetupPlanViewer: React.FC<IProps> = observer((props) => {
  if (props.plan.loading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Loader />
      </Box>
    );
  }

  return (
    <SetupPlanForm
      plan={props.plan}
      initialValues={props.initialValues}
      onSwitchToChoosePlan={props.onSwitchToChoosePlan}
      onSubmit={props.onSubmit}
    />
  );
});

export default SetupPlanViewer;

import Decimal from 'decimal.js';
import Long from 'long';
import {action, computed, makeObservable, observable} from 'mobx';
import {ca2types} from '../../api/proto';
import {formatBytes} from '../../utils/format/formatBytes';
import PriceFormatter from '../../utils/priceFormatter';
import {PROVIDER_DIGITAL_OCEAN_ID, PROVIDER_LINODE_ID, PROVIDER_VULTR_ID} from '../Server';
import Addon from '../Server/Addon';

function updatePointsRateFormatter(rateFormatter: PriceFormatter, points: number, action: 'add' | 'subtract') {
  if (points === 0) {
    return;
  }
  switch (action) {
    case 'add':
      rateFormatter.addPoints(points);
      break;
    case 'subtract':
      rateFormatter.subtractPoints(points);
      break;
  }
}

export class BasePlan {
  @observable id: string | null = null;
  @observable classId: string | null = null;
  @observable providerId: string | null = null;
  @observable regionIds: string[] = [];
  @observable datacenterIds: string[] = [];
  @observable cpu: ca2types.ICPU | null = null;
  @observable gpu: ca2types.IGPU | null = null;
  @observable ramSize: Long | null = null;
  @observable storageSize: Long | null = null;
  @observable private monthlyRate: ca2types.IAmount | null = null;
  @observable private hourlyRate: ca2types.IAmount | null = null;
  @observable private windowsLicenseMonthlyRate: ca2types.IAmount | null = null;
  @observable private windowsLicenseHourlyRate: ca2types.IAmount | null = null;
  @observable transfer: Long | null = null;
  @observable benefitIds: string[] = [];
  @observable availableAddonIds: string[] = [];
  @observable firstPaymentHours: number = 1;

  @observable monthlyRateFormatter: PriceFormatter;
  @observable hourlyRateFormatter: PriceFormatter;

  @observable windowsLicenseMonthlyRateFormatter: PriceFormatter;
  @observable windowsLicenseHourlyRateFormatter: PriceFormatter;

  @observable monthlyTotalRateFormatter: PriceFormatter;
  @observable hourlyTotalRateFormatter: PriceFormatter;

  @observable isWindowsLicenseCounted = false;

  constructor(public raw: ca2types.IServerPlan) {
    makeObservable(this);
    this.update_(raw);

    this.monthlyRateFormatter = new PriceFormatter(raw.monthlyRate);
    this.hourlyRateFormatter = new PriceFormatter(raw.hourlyRate);

    this.windowsLicenseMonthlyRateFormatter = new PriceFormatter(raw.windowsLicenseMonthlyRate);
    this.windowsLicenseHourlyRateFormatter = new PriceFormatter(raw.windowsLicenseHourlyRate);

    this.monthlyTotalRateFormatter = new PriceFormatter(raw.monthlyRate);
    this.hourlyTotalRateFormatter = new PriceFormatter(raw.hourlyRate);
  }

  @computed get firstHoursRateFormatter(): PriceFormatter {
    const hourlyPoints = new Decimal(this.hourlyTotalRateFormatter.points);
    const totalPoints = hourlyPoints.times(this.firstPaymentHours);
    const totalDollars = totalPoints.dividedBy(10000).toDecimalPlaces(2).toNumber();
    const formattedDollars = `$${totalDollars.toFixed(2)}`;

    const amount: ca2types.IAmount = {
      points: Long.fromNumber(totalPoints.toNumber()),
      formatted: formattedDollars,
    };

    return new PriceFormatter(amount);
  }

  @computed get allowWindowsImages(): boolean {
    return !!this.windowsLicenseHourlyRate || !!this.windowsLicenseMonthlyRate;
  }

  @computed get isVultr(): boolean {
    return this.providerId === PROVIDER_VULTR_ID;
  }

  @computed get isDataOcean(): boolean {
    return this.providerId === PROVIDER_DIGITAL_OCEAN_ID;
  }

  @computed get isLinode(): boolean {
    return this.providerId === PROVIDER_LINODE_ID;
  }

  @computed get isGpuServer(): boolean {
    return !!this.gpu?.model;
  }

  @computed get ramSizeFormatted(): string {
    return formatBytes(this.ramSize?.toNumber() || 0, 0);
  }

  @computed get transferFormatted(): string {
    return formatBytes(this.transfer?.toNumber() || 0, 0);
  }

  @computed get cpuInfo(): string {
    if (this.cpu?.model) {
      return `${this.cpu.count || 0} x ${this.cpu.model}`;
    }

    return `${this.cpu?.count || 0}`;
  }

  @computed get gpuInfo(): string {
    if (this.gpu?.model) {
      return `${this.gpu.count || 0} x ${this.gpu.model}`;
    }

    return `${this.cpu?.count || 0}`;
  }

  @computed get storageSizeFormatted(): string {
    return formatBytes(this.storageSize?.toNumber() || 0, 0);
  }

  @action private update_ = (raw: ca2types.IServerPlan) => {
    Object.assign(this, raw);
  };

  @action addAddonRate = (addon: Addon) => {
    updatePointsRateFormatter(this.hourlyTotalRateFormatter, addon.hourlyRateFormatter.points, 'add');
    updatePointsRateFormatter(this.monthlyTotalRateFormatter, addon.monthlyRateFormatter.points, 'add');
  };

  @action subtractAddonRate = (addon: Addon) => {
    updatePointsRateFormatter(this.hourlyTotalRateFormatter, addon.hourlyRateFormatter.points, 'subtract');
    updatePointsRateFormatter(this.monthlyTotalRateFormatter, addon.monthlyRateFormatter.points, 'subtract');
  };

  @action addWindowsLicenseRate = () => {
    if (this.isWindowsLicenseCounted) {
      return;
    }
    updatePointsRateFormatter(this.hourlyTotalRateFormatter, this.windowsLicenseHourlyRateFormatter.points, 'add');
    updatePointsRateFormatter(this.monthlyTotalRateFormatter, this.windowsLicenseMonthlyRateFormatter.points, 'add');
    this.isWindowsLicenseCounted = true;
  };

  @action subtractWindowsLicenseRate = () => {
    if (!this.isWindowsLicenseCounted) {
      return;
    }

    updatePointsRateFormatter(this.hourlyTotalRateFormatter, this.windowsLicenseHourlyRateFormatter.points, 'subtract');
    updatePointsRateFormatter(
      this.monthlyTotalRateFormatter,
      this.windowsLicenseMonthlyRateFormatter.points,
      'subtract',
    );
    this.isWindowsLicenseCounted = false;
  };
}

export default BasePlan;

import React from 'react';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import {useStore} from '../../stores/AppStore';
import {Plan} from '../../stores/Plan';
import {SERVER_PLAN_ID_STORE_KEY} from '../../stores/Plan/PlansStore';
import browserStorage from '../../utils/browserStorage';

export function usePlanByGetParam(): Plan | null {
  const {plansStore} = useStore();
  const isFirstRender = useIsFirstRender();
  const [plan, setPlan] = React.useState<Plan | null>(null);

  const storedPlanId = browserStorage.getItem(SERVER_PLAN_ID_STORE_KEY);

  React.useEffect(() => {
    if (!isFirstRender) {
      return;
    }

    const fetchPlan = async () => {
      if (!storedPlanId) {
        return;
      }

      const foundedPlan = await plansStore.getPlanById(storedPlanId);

      if (foundedPlan) {
        setPlan(foundedPlan);
      }

      browserStorage.removeItem(SERVER_PLAN_ID_STORE_KEY);
    };

    fetchPlan();
  }, [plansStore, storedPlanId, isFirstRender]);

  return plan;
}

import {action, makeObservable, observable} from 'mobx';
import {AppStore} from '../stores/AppStore';
import isObject from '../utils/isObject';
import logger from '../utils/logger';
import wait from '../utils/wait';
import EventEmitter from './EventEmitter';
import {ca2} from './proto';

const WS_END_POINT = '/ws';
const STAGE_WS_URL = 'wss://preprod.crypadvise.com/ws';

const WS_RECONNECT_DELAY = 2000;
const WS_RECONNECT_TIMEOUT = 20000;

export enum WsNetworkEvent {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',

  CONNECTION_OPEN = 'CONNECTION_OPEN',
  CONNECTION_CLOSE = 'CONNECTION_CLOSE',

  INVALID_TOKEN = 'INVALID_TOKEN',
  API_ERROR = 'API_ERROR',
}

interface IEventHandler {
  eventName: keyof ca2.WsEvent;
  handler?: (res: ca2.WsEvent) => void;
}

export class WsNetwork extends EventEmitter {
  private connectionStamp: number = 0;
  private webSocket?: WebSocket;

  private eventHandlers: IEventHandler[] = [];

  constructor(protected app: AppStore) {
    super();
    makeObservable(this);

    if (this.app.authStore.getToken()) {
      this.connect();
    }

    window.addEventListener('offline', () => {
      this.emit(WsNetworkEvent.OFFLINE);
    });

    window.addEventListener('online', () => {
      this.emit(WsNetworkEvent.ONLINE);
    });
  }

  @observable public initialized = false;
  @observable public connectionIsOpen = false;

  connect = () => {
    let url = `${window.location.protocol.indexOf('https') >= 0 ? 'wss' : 'ws'}://${
      window.location.host
    }${WS_END_POINT}`;

    if (window.location.host.includes('localhost')) {
      //TODO need to find how to do proxy correctly
      url = STAGE_WS_URL;
    }

    logger.debug('%cWS: Connecting to URL: ', 'color: green', url);

    this.clearReconnectionTimeout();
    this.connectionStamp = new Date().getTime();
    this.webSocket = new WebSocket(url);

    this.webSocket.binaryType = 'arraybuffer';

    this.webSocket.onopen = this.onopen_;
    this.webSocket.onmessage = this.onmessage_;
    this.webSocket.onerror = this.onerror_;
    this.webSocket.onclose = this.onclose_;

    this.reconnectionTimeout_ = setTimeout(() => {
      logger.debug(`%c${new Date().toISOString()} - WS: Connection closed by timeout`, 'color: red');
      this.webSocket?.close();
    }, WS_RECONNECT_TIMEOUT);
  };

  disconnect = () => {
    logger.debug('%cWS: Manual close', 'color: red');
    this.webSocket?.close();
  };

  @action protected onopen_ = async (ev) => {
    console.debug(
      `%c${new Date().toISOString()} - WS: Connection open by token: [${this.app.authStore.getToken()}] in ${
        new Date().getTime() - this.connectionStamp
      }ms: `,
      'color: green',
      ev,
    );

    this.webSocket?.send(ca2.WsAuth.encode(ca2.WsAuth.create({token: this.app.authStore.getToken()})).finish());

    this.clearReconnectionTimeout();
    this.connectionIsOpen = true;

    this.emit(WsNetworkEvent.CONNECTION_OPEN, {initial: !this.initialized});

    this.initialized = true;
  };

  private onerror_ = (e) => {
    console.debug(
      `%c${new Date().toISOString()} - WS: Experienced an error in ${new Date().getTime() - this.connectionStamp}ms: `,
      'color: red',
      e,
    );
  };

  private onmessage_ = (message: MessageEvent) => {
    if (!message || !message.data) {
      return;
    }

    const serverMessage = ca2.WsEvent.decode(new Uint8Array(message.data));
    this.processMessageEvent(serverMessage);
  };

  private processMessageEvent = (serverMessage: ca2.WsEvent) => {
    logger.log(`%cWS: message:(${new Date().toISOString()})`, 'color: green', serverMessage);

    if (serverMessage.errors.length) {
      logger.error(`%cWS: Error message:(${new Date().toISOString()})`, 'color: red', serverMessage);

      for (const error of serverMessage.errors) {
        if ([ca2.ServerError.SE_EXPIRED_TOKEN_ERROR, ca2.ServerError.SE_INVALID_TOKEN_ERROR].includes(error)) {
          this.emit(WsNetworkEvent.INVALID_TOKEN, serverMessage);
        }
      }
    }

    for (const messageKey in serverMessage) {
      if (!Object.prototype.hasOwnProperty.call(serverMessage, messageKey)) {
        continue;
      }
      this.callEventHandler_(messageKey, serverMessage);
    }
  };

  private callEventHandler_ = (messageKey: string, serverMessage: ca2.WsEvent) => {
    this.eventHandlers.forEach((t) => {
      try {
        if (t.eventName.toLowerCase() === messageKey.toLowerCase()) {
          t.handler?.(serverMessage[messageKey]);
        }
      } catch (e) {
        console.debug(e);
      }
    });

    this.emit(messageKey, serverMessage[messageKey]);
    this.callSubEventHandler_(messageKey, serverMessage);
  };

  private callSubEventHandler_ = (messageKey: string, serverMessage: ca2.WsEvent) => {
    try {
      const messageData = serverMessage[messageKey];

      for (const subMessageKey in messageData) {
        if (!isObject(messageData) || !Object.prototype.hasOwnProperty.call(messageData, subMessageKey)) {
          continue;
        }

        this.emit(`${messageKey}.${subMessageKey}`, messageData[subMessageKey]);
      }
    } catch (e) {
      console.debug(e);
    }
  };

  @action private onclose_ = (e: CloseEvent) => {
    console.debug(`%c${new Date().toISOString()} - WS: Connection is closed:`, 'color: red', e);
    this.emit(WsNetworkEvent.CONNECTION_CLOSE);
    this.connectionIsOpen = false;

    if (this.app.authStore.getToken()) {
      this.reconnect_();
    }
  };

  private reconnectionTimeout_: NodeJS.Timeout | null = null;

  private clearReconnectionTimeout = () => {
    if (this.reconnectionTimeout_) {
      logger.debug(`%c${new Date().toISOString()} - WS: Reconnection timeout clear`, 'color: gray');
      clearTimeout(this.reconnectionTimeout_);
      this.reconnectionTimeout_ = null;
    }
  };

  private reconnect_ = async () => {
    logger.debug('%cWS: Reconnecting...', 'color: red');
    await wait(WS_RECONNECT_DELAY);
    this.connect();
  };
}

export default WsNetwork;

import {action, computed, makeObservable, observable} from 'mobx';
import {ca2servers, ca2types} from '../../api/proto';
import {ZERO_AMOUNT} from '../../constants';
import PriceFormatter from '../../utils/priceFormatter';
import PaymentOption from '../BillingStore/PaymentOption';
import ServersStore from '../Server';
import Addon from '../Server/Addon';
import ImageOptions from '../Server/options/ImageOptions';
import BasePlan from './BasePlan';
import PlansStore from './PlansStore';

export class Plan extends BasePlan {
  images: ImageOptions = new ImageOptions();

  @observable loading: boolean = false;

  constructor(public raw: ca2types.IServerPlan, private plansStore: PlansStore) {
    super(raw);
    makeObservable(this);
  }

  @computed get serversStore(): ServersStore {
    return this.plansStore.app.serversStore;
  }

  @computed get hasBalanceToPayFirstHours(): boolean {
    return this.firstHoursRateFormatter.isLessOrEqualPoints(this.plansStore.app.billingStore.balanceFormatter.points);
  }

  @computed get hasBalanceToPayByMonth(): boolean {
    return this.monthlyRateFormatter.isLessOrEqualPoints(this.plansStore.app.billingStore.balanceFormatter.points);
  }

  @computed get addons(): Addon[] {
    return this.serversStore.addons.list.filter(({providerId}) => this.providerId && providerId === this.providerId);
  }

  @computed get benefits(): ca2types.IBenefit[] {
    return this.serversStore.benefits.findByIds(this.benefitIds);
  }

  @computed get regions(): ca2types.IRegion[] {
    return this.serversStore.regions.findByIds(this.regionIds);
  }

  @computed get datacenters(): ca2types.IDatacenter[] {
    return this.serversStore.datacenters.list.filter(({id}) => id && this.datacenterIds.includes(id));
  }

  @computed get provider() {
    if (!this.providerId) {
      return null;
    }

    return this.serversStore.providers.findById(this.providerId) || null;
  }

  @action setLoading = (loading: boolean) => {
    this.loading = loading;
  };

  findRegionByDatacenterId = (datacenterId?: string | null): ca2types.IRegion | undefined => {
    if (!datacenterId) {
      return undefined;
    }

    return this.regions.find((region) => region.datacenterIds?.includes(datacenterId));
  };

  findDatacentersByRegionId = (regionId?: string | null): ca2types.IDatacenter[] => {
    const foundRegion = this.regions.find(({id}) => regionId && id === regionId);

    if (!foundRegion) {
      return this.datacenters;
    }

    return this.datacenters.filter(({id}) => id && foundRegion.datacenterIds?.includes(id));
  };

  @action resetSetupPlanState = () => {
    this.initImageFamily();

    this.monthlyTotalRateFormatter.reset();
    this.hourlyTotalRateFormatter.reset();
  };

  initializeSetupPlan = async () => {
    this.setLoading(true);

    await this.loadImagesList_();
    this.initImageFamily();

    await this.loadPaymentOptions_();

    this.setLoading(false);
  };

  // PLAN IMAGES

  @action private loadImagesList_ = async () => {
    const {res} = await this.plansStore.request({
      servers: {
        imagesList: {
          planId: this.id,
        },
      },
    });

    if (res?.servers?.imagesList) {
      this.processLoadImagesList_(res.servers.imagesList);
    }
  };

  private processLoadImagesList_ = (res: ca2servers.IImagesListResponse) => {
    if (res?.items) {
      this.images.init(res.items);
    }
  };

  @computed get imageFamilies(): Record<string, ca2types.IImage[]> {
    return this.images.getDictionaryByFamily(this.providerId, this.allowWindowsImages);
  }

  @computed get imageFamiliesList(): string[] {
    return Object.keys(this.imageFamilies).map((family) => family);
  }

  @observable selectedImageFamilyName: string | null = null;
  @observable selectedImageId: string | null = null;

  @computed get imagesList() {
    if (!this.selectedImageFamilyName) {
      return [];
    }

    const list = this.imageFamilies[this.selectedImageFamilyName] || [];

    if (!this.allowWindowsImages) {
      return list.filter((image) => !image.isWindows);
    }

    return list;
  }

  @computed get selectedImage() {
    return this.images.findImageById(this.selectedImageId);
  }

  @computed get isWindow() {
    return !!this.selectedImage?.isWindows;
  }

  @computed get passwordAllow() {
    return !this.selectedImage?.passwordDisabled;
  }

  @action initImageFamily() {
    const familyName = this.imageFamiliesList[0];
    this.changeImageFamilyName(familyName);
  }

  @action changeImageFamilyName = (family: string | null) => {
    const image = family ? this.imageFamilies[family][0] : null;

    if (image) {
      this.setSelectedImageId(image.id);

      if (image.isWindows) {
        this.addWindowsLicenseRate();
      } else {
        this.subtractWindowsLicenseRate();
      }
    }

    this.selectedImageFamilyName = family;
  };

  @action setSelectedImageId = (imageId?: string | null) => {
    this.selectedImageId = imageId || null;
  };

  findImageById = (imageId: string | null) => {
    return this.images.findImageById(imageId);
  };

  // PLAN IMAGES END

  // PLAN PAYMENT OPTIONS

  @observable private isPaymentOptionsLoaded_: boolean = false;
  @observable paymentOptions: PaymentOption[] = [];
  @observable hoursLeftWithBalance: number = 0;
  @observable firstPaymentWithAddonsFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);
  @observable maxAmountFormatter: PriceFormatter = new PriceFormatter(ZERO_AMOUNT);

  private loadPaymentOptions_ = async () => {
    if (this.isPaymentOptionsLoaded_) {
      return;
    }
    this.isPaymentOptionsLoaded_ = true;

    const {res} = await this.plansStore.request({
      servers: {
        paymentOptions: {
          planId: this.id,
        },
      },
    });

    if (res?.servers?.paymentOptions) {
      this.processLoadPaymentOptions_(res.servers.paymentOptions);
    }
  };

  @action private processLoadPaymentOptions_ = (res: ca2servers.IPaymentOptionsResponse) => {
    this.hoursLeftWithBalance = res.hoursLeftWithGivenAmount || 0;
    this.paymentOptions = res.items ? res.items.map((raw) => new PaymentOption(raw)) : [];
    if (res.firstPaymentWithAddons) {
      this.firstPaymentWithAddonsFormatter = new PriceFormatter(res.firstPaymentWithAddons);
    }
    if (res.maxAmount) {
      this.maxAmountFormatter = new PriceFormatter(res.maxAmount);
    }
  };

  // PLAN PAYMENT OPTIONS END
}

export default Plan;

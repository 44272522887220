import {action, computed, makeObservable, observable} from 'mobx';
import {ca2servers} from '../../api/proto';
import APILayer from '../APILayer';
import {AppStore} from '../AppStore';
import Plan from './Plan';
import PlansFilterStore from './PlansFilterStore';

export const SERVER_PLAN_ID_STORE_KEY = '_plan_id';

export class PlansStore extends APILayer {
  constructor(public app: AppStore) {
    super(app);
    makeObservable(this);

    this.filter = new PlansFilterStore(app, this);
  }

  @observable filter: PlansFilterStore;
  @observable upgradableFromPlan: Plan | null = null;

  @observable private plans_: Plan[] = [];
  @observable private plansForUpgradable_: Plan[] = [];

  @computed get plans() {
    return this.plans_
      .slice()
      .sort((planA, planB) => planA.monthlyRateFormatter.points - planB.monthlyRateFormatter.points);
  }

  @computed get plansForUpgradable() {
    return this.plansForUpgradable_
      .slice()
      .sort((planA, planB) => planA.monthlyRateFormatter.points - planB.monthlyRateFormatter.points);
  }

  @action setUpgradableFromPlan = (plan: Plan | null) => {
    this.upgradableFromPlan = plan;
  };

  findById = (planId: string | null): Plan | null => {
    return this.plans.find(({id}) => planId && id === planId) || null;
  };

  private plansListRequest_ = (plansList: ca2servers.IPlansListRequest) => {
    return this.request({
      servers: {
        plansList,
      },
    });
  };

  private loadPlansList_ = async () => {
    this.clear_();

    const {res} = await this.plansListRequest_(this.filter.requestData);

    if (res?.servers?.plansList) {
      this.processLoadPlans_(res?.servers?.plansList);
    }
  };

  @action private processLoadPlans_ = (res: ca2servers.IPlansListResponse) => {
    if (res?.items?.length) {
      this.plans_ = res.items.map((raw) => new Plan(raw, this));
    }
  };

  getPlanById = async (planId: string) => {
    const plan = this.findById(planId);

    if (!plan) {
      const {res} = await this.plansListRequest_({
        ids: [planId],
      });

      const rawPlan = res?.servers?.plansList?.items?.[0];

      return rawPlan ? new Plan(rawPlan, this) : null;
    }

    return plan;
  };

  loadUpgradablePlansList = async (upgradableFromPlan: Plan) => {
    this.clearUpgradablePlans_();

    this.setUpgradableFromPlan(upgradableFromPlan);

    const {res} = await this.plansListRequest_({
      ...this.filter.requestData,
      upgradableFromPlanId: upgradableFromPlan.id,
    });

    if (res?.servers?.plansList) {
      this.processLoadUpgradablePlansList_(res?.servers?.plansList);
    }
  };

  @action private processLoadUpgradablePlansList_ = (res: ca2servers.IPlansListResponse) => {
    this.plansForUpgradable_ = this.upgradableFromPlan ? [this.upgradableFromPlan] : [];

    if (res?.items?.length) {
      this.plansForUpgradable_ = [...this.plansForUpgradable_, ...res.items.map((raw) => new Plan(raw, this))];
    }
  };

  reload = async () => {
    if (this.upgradableFromPlan) {
      this.loadUpgradablePlansList(this.upgradableFromPlan);
    } else {
      this.loadPlansList_();
    }
  };

  @action private clear_ = () => {
    this.plans_ = [];
  };

  @action private clearUpgradablePlans_ = () => {
    this.plansForUpgradable_ = [];
  };

  @action resetUpgradablePlanData = () => {
    this.clearUpgradablePlans_();
    this.upgradableFromPlan = null;
    this.filter.reset();
  };

  @action reset = () => {
    this.clear_();
    this.clearUpgradablePlans_();

    this.upgradableFromPlan = null;
  };
}

export default PlansStore;

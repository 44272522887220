import {Box, FormHelperText, styled} from '@mui/material';
import classNames from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getApiCheckDomainError} from '../../../../api/getApiError';
import {ca2domains} from '../../../../api/proto';
import SearchInput from '../../../../components/UI/SearchInput';
import {useStore} from '../../../../stores/AppStore';
import DomainFilterBar from './DomainFilterBar';
import DomainsSearchLogoTitle from './DomainsSearchLogoTitle';

const SearchInputBox = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  width: '100%',
  textAlign: 'center',
  '&.search-start': {
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
  '&:not(.search-start)': {
    maxWidth: 775,
  },
}));

const StyledSearchInput = styled(SearchInput)(({theme}) => ({
  padding: `${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(4)}!important`,
  borderColor: `${theme.palette.brand.primary} !important`,
  borderWidth: '2px !important',
  borderRadius: '12px !important',
  borderBottomRightRadius: '0 !important',
  borderBottomLeftRadius: '0 !important',

  '& .MuiInputAdornment-positionStart': {
    display: 'none',
  },
}));

export interface DomainSearchInputRef {
  handleSearch: (searchText: string) => Promise<void>;
}

export const DomainSearchInput = observer(
  React.forwardRef<DomainSearchInputRef, {}>((_, ref) => {
    const {t} = useTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const {domainsView, domainsStore} = useStore();
    const [searchText, setSearchText] = React.useState(domainsStore.search.searchText);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const prevSearchTextRef = React.useRef<string>(searchText);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      prevSearchTextRef.current = searchText;
      setSearchText(e.target.value);
    };

    const handleClear = () => {
      prevSearchTextRef.current = searchText;
      setSearchText('');
    };

    const handleSearch = async (searchText: string) => {
      const trimmedSearchText = searchText.trim();
      if (!trimmedSearchText) {
        setErrorMessage(t('domains_search_input_empty_error'));
        return;
      }

      setSearchText(trimmedSearchText);

      setErrorMessage(null);

      const {res} = await domainsView.searchDomain(trimmedSearchText);

      if (res?.errors?.includes(ca2domains.CheckDomainError.CDN_INVALID_DOMAIN)) {
        setErrorMessage(getApiCheckDomainError(ca2domains.CheckDomainError.CDN_INVALID_DOMAIN));
      }

      setErrorMessage(null);
    };

    const handleClickSearchButton = () => {
      handleSearch(searchText);
    };

    React.useImperativeHandle(ref, () => ({
      handleSearch: handleSearch,
    }));

    React.useEffect(() => {
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, []);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSearch(searchText);
      }
    };

    return (
      <>
        <SearchInputBox
          sx={{maxWidth: !domainsView.isSearchDomainStart ? 740 : 'none'}}
          className={classNames({'search-start': domainsView.isSearchDomainStart})}
        >
          {!domainsView.isSearchDomainStart ? <DomainsSearchLogoTitle /> : null}

          {errorMessage || !domainsView.isSearchDomainStart ? (
            <FormHelperText
              sx={(theme) => ({
                marginTop: '0px !important',
                marginBottom: theme.spacing(1),
                height: 16,
              })}
              error={!!errorMessage}
            >
              {errorMessage}
            </FormHelperText>
          ) : null}

          <StyledSearchInput
            inputRef={inputRef}
            fullWidth
            value={searchText}
            placeholder={t('domains_search_input_placeholder')}
            onChange={handleChange}
            onClickSearchButton={handleClickSearchButton}
            onClear={handleClear}
            searchByButton
            onKeyDown={handleKeyPress}
            error={!!errorMessage}
          />
          <DomainFilterBar />
        </SearchInputBox>
      </>
    );
  }),
);

export default DomainSearchInput;

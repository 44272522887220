import {observer} from 'mobx-react';
import React, {useCallback, useEffect} from 'react';
import {useParams} from 'react-router';
import {animated} from 'react-spring';
import Div100vh from '../../components/Div100vh';
import PaymentViewer from '../../components/PaymentViewer';
import {useOrderTransition} from '../../hooks/useOrderTransition';
import {navigateToBilling} from '../../routes/navigateTo';
import {BillingRouteParams} from '../../routes/Paths';
import {useStore} from '../../stores/AppStore';
import BillingDetailsViewer from './BillingDetailsViewer';
import BillingPageTopBar from './BillingPageTopBar';

export enum BillingPageTab {
  BILLING_DETAILS = 'details',
  TOP_UP_BALANCE = 'topUpBalance',
}

const tabsOrder = {
  [BillingPageTab.BILLING_DETAILS]: 1,
  [BillingPageTab.TOP_UP_BALANCE]: 2,
};

function isTabValid(value: unknown): value is BillingPageTab {
  return typeof value === 'string' && Object.values(BillingPageTab).includes(value as BillingPageTab);
}

export const BillingPage: React.FC = observer(() => {
  const params = useParams<BillingRouteParams>();
  const {billingStore} = useStore();

  const currentTab = isTabValid(params.tabType) ? params.tabType : BillingPageTab.BILLING_DETAILS;

  const prevTab =
    currentTab === BillingPageTab.BILLING_DETAILS ? BillingPageTab.TOP_UP_BALANCE : BillingPageTab.BILLING_DETAILS;

  const initBilling = useCallback(() => billingStore.initBillingPageState(), [billingStore]);
  const resetBilling = useCallback(() => billingStore.resetBillingPageState(), [billingStore]);

  useEffect(() => {
    initBilling();
    return resetBilling;
  }, [initBilling, resetBilling]);

  const switchTab = (tab: BillingPageTab) => () => navigateToBilling(tab);

  const transitions = useOrderTransition({
    currentStep: tabsOrder[currentTab],
    prevStep: tabsOrder[prevTab],
  });

  const renderTab = (styles: any, step: number) => {
    switch (step) {
      case tabsOrder[BillingPageTab.TOP_UP_BALANCE]:
        return (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            <PaymentViewer onCancelPaymentOrder={switchTab(BillingPageTab.BILLING_DETAILS)} />
          </animated.div>
        );
      case tabsOrder[BillingPageTab.BILLING_DETAILS]:
        return (
          <animated.div style={styles} className="animated-tab animated-tab--height100">
            <BillingDetailsViewer onTopUpBalance={switchTab(BillingPageTab.TOP_UP_BALANCE)} />
          </animated.div>
        );
      default:
        return null;
    }
  };

  return (
    <Div100vh className="page page--billing">
      <BillingPageTopBar currentTab={currentTab} onBack={switchTab(BillingPageTab.BILLING_DETAILS)} />
      <div className="page__inner">{transitions(renderTab)}</div>
    </Div100vh>
  );
});

export default BillingPage;

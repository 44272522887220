import React from 'react';

import {Box, Button, Menu as MuiMenu, styled, Typography, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {ca2types} from '../../api/proto';
import {ReactComponent as FilterIcon} from '../../assets/icons/filter-by-type.svg';
import MenuItem from '../../components/UI/MenuItem';
import {Switch} from '../../components/UI/Switch';
import {t} from '../../i18n';

export const AVAILABLE_OPERATION_TYPES: ca2types.BalanceOperationType[] = [
  ca2types.BalanceOperationType.BOT_ADMIN_CORRECTION,
  ca2types.BalanceOperationType.BOT_INSTANCE_HOUR,
  ca2types.BalanceOperationType.BOT_PAYMENT,
  ca2types.BalanceOperationType.BOT_PROMO_CODE,
  ca2types.BalanceOperationType.BOT_REFERRAL_OPERATION,
  ca2types.BalanceOperationType.BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE,
  ca2types.BalanceOperationType.BOT_UNKNOWN,
];

export const BALANCE_OPERATION_TITLES: Record<ca2types.BalanceOperationType, string> = {
  [ca2types.BalanceOperationType.BOT_ADMIN_CORRECTION]: t('billing_balance_operation_type_admin_correction'),
  [ca2types.BalanceOperationType.BOT_INSTANCE_HOUR]: t('billing_balance_operation_type_instance_hour'),
  [ca2types.BalanceOperationType.BOT_PAYMENT]: t('billing_balance_operation_type_payment'),
  [ca2types.BalanceOperationType.BOT_PROMO_CODE]: t('billing_balance_operation_type_promo_code'),
  [ca2types.BalanceOperationType.BOT_REFERRAL_OPERATION]: t('billing_balance_operation_type_referral_operation'),
  [ca2types.BalanceOperationType.BOT_TRANSFER_FROM_BONUS_TO_MAIN_BALANCE]: t(
    'billing_balance_operation_type_from_bonus_to_balance',
  ),
  [ca2types.BalanceOperationType.BOT_V1_OPERATION]: t('billing_balance_operation_type_v1_operation'),
  [ca2types.BalanceOperationType.BOT_DOMAIN_REGISTRATION]: t('billing_balance_operation_type_domain_registration'),
  [ca2types.BalanceOperationType.BOT_DOMAIN_RENEWAL]: t('billing_balance_operation_type_domain_renewal'),
  [ca2types.BalanceOperationType.BOT_UNKNOWN]: t('billing_balance_operation_type_unknown'),
};

const Menu = styled(MuiMenu)(({theme}) => ({
  marginTop: theme.spacing(3),

  '& .MuiPaper-root': {
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.border.tertiary}`,
    borderRadius: '12px',
  },
  '& .list-wrapper': {
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    overflow: 'hidden',
  },
  '& .MuiMenuItem-root': {
    display: 'flex',
    backgroundColor: theme.palette.backgroundPrimary,
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& .MuiStack-root': {
      width: 'auto !important',
    },
  },
  '& .select-all': {
    marginTop: theme.spacing(2),
  },
}));

interface IProps {
  availableTypes: ca2types.BalanceOperationType[];
  onToggleTypes?(types: ca2types.BalanceOperationType[]): void;
}

const OperationTypeFilter: React.FC<IProps> = (props) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuEl(null);
  };

  const handleToggle = (type: ca2types.BalanceOperationType) => {
    const types = [...props.availableTypes];
    const index = types.indexOf(type);

    if (index > -1) {
      types.splice(index, 1);
    } else {
      types.push(type);
    }

    props.onToggleTypes?.(types);
  };

  const handleSelectAll = () => {
    props.onToggleTypes?.(AVAILABLE_OPERATION_TYPES);
  };

  const initialList = Object.entries(BALANCE_OPERATION_TITLES);
  const filteredList = initialList.filter(([type]) => !!+type); //exclude BOT_UNKNOWN type

  const hasHiddenTypes = initialList.length !== props.availableTypes.length;
  const hiddenTypesCount = initialList.length - props.availableTypes.length;

  return (
    <>
      <Button
        variant={hasHiddenTypes ? 'contained' : 'outlined'}
        onClick={handleOpenMenu}
        startIcon={<FilterIcon style={{fill: theme.palette.body.primary}} />}
      >
        {hasHiddenTypes
          ? hiddenTypesCount > 1
            ? t('billing_balance_operation_type_filter_button_hidden_types', {count: hiddenTypesCount})
            : t('billing_balance_operation_type_filter_button_hidden_type', {count: hiddenTypesCount})
          : t('billing_balance_operation_type_filter_button')}
      </Button>

      <Menu
        slotProps={{
          paper: {
            style: {
              width: 345,
              minHeight: 465,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={menuEl}
        open={!!menuEl}
        onClose={handleCloseMenu}
      >
        <Typography variant="label2" color="body.placeholder" gutterBottom>
          {t('billing_balance_operation_type_filter_label')}
        </Typography>

        <Box className="list-wrapper">
          {filteredList.map(([type, label]) => (
            <MenuItem key={type} onClick={() => handleToggle(+type)}>
              {label}
              <Switch checked={props.availableTypes.includes(+type)} />
            </MenuItem>
          ))}
        </Box>

        {hasHiddenTypes ? (
          <Button className="select-all" variant="outlined" fullWidth onClick={handleSelectAll}>
            {t('billing_balance_operation_type_filter_select_all_button')}
          </Button>
        ) : null}
      </Menu>
    </>
  );
};

export default OperationTypeFilter;
